<template>
  <div class="layout-main">
    <section v-if="pathDetail" class="path" :style="fixedPaddingStyle">
      <ColumnLayout :router-name="$route.name" :map-scale="mapScale" :map-scale-function="mapScaleEnd">
        <template v-slot:column-one>
          <div class="trail-nav custom-z-index" ref="trailNav">
            <div class="trail-nav-item fs-h5 text-center" v-text="pathDetail.name"></div>
            <div class="trail-nav-item d-flex justify-content-center flex-wrap">
              <div
                  class="operating-btn"
                  v-for="(item, index) in navInfo"
                  :key="index"
                  @click="item.function"
              >
                <Icon v-if="item.srcName" :name="item.srcName" size="24" />
                <div class="fs-small-11" v-text="item.name"></div>
              </div>
            </div>
            <div class="trail-nav-item d-flex justify-content-center">
              <div class="btn common-oval-btn dark fs-btn-sm" v-text="planMakeBtnName" @click="pathCreateClicked"></div>
            </div>
          </div>
          <div class="trail-info">
            <div class="d-flex flex-column align-items-center px-2">
              <div class="trail-info-name">
                <Icon class="trail-info-icon" name="directions_walk" size="18" />
                <div class="trail-info-text">行走</div>
              </div>
              <div class="trail-info-value d-flex align-items-end">
                <div class="fs-h5" v-text="totalWalkTimeHr"></div>
                <div class="fs-small-11">h</div>
                <div class="fs-h5" v-text="totalWalkTimeMin"></div>
                <div class="fs-small-11">min</div>
              </div>
            </div>

            <div class="d-flex flex-column align-items-center px-2">
              <div class="trail-info-name">
                <Icon class="trail-info-icon" name="distance" size="18" />
                <div class="trail-info-text">距離</div>
              </div>
              <div class="trail-info-value d-flex align-items-end">
                <div class="fs-h5" v-text="totalDistance"></div>
                <div class="fs-small-11">km</div>
              </div>
            </div>

            <div class="d-flex flex-column align-items-center px-2">
              <div class="trail-info-name">
                <Icon class="trail-info-icon" name="climb-up" size="18" />
                <div class="trail-info-text">總升</div>
              </div>
              <div class="trail-info-value d-flex align-items-end">
                <div class="fs-h5" v-text="totalUp"></div>
                <div class="fs-small-11">m</div>
              </div>
            </div>

            <div class="d-flex flex-column align-items-center px-2">
              <div class="trail-info-name">
                <Icon class="trail-info-icon" name="climb-down" size="18" />
                <div class="trail-info-text">總降</div>
              </div>
              <div class="trail-info-value d-flex align-items-end">
                <div class="fs-h5" v-text="totalDown"></div>
                <div class="fs-small-11">m</div>
              </div>
            </div>

          </div>
        </template>
        <template v-slot:column-two>
<!--          <journey-day :journey-item="pathDetail.journey" :show-timestamp="false" :show-opt-btn="false" :show-weather="false" @optclick="milestoneOptClicked"></journey-day>-->
          <journey-day v-if="pathDetail.journey" :journey-item="pathDetail.journey"></journey-day>
        </template>
        <template v-slot:column-three>
          <div class="trail-map">
            <img class="thumbnail d-lg-none" :src="[pathDetail.picUrl]" alt="路線縮圖">
            <button class="btn scale d-lg-none" @click="mapScaleToggle">
              <img :src="mapBtnSvg" alt="地圖縮放鍵">
            </button>
            <div class="path-map-group custom-z-index common-transition">
              <path-map ref="map" v-if="shownPathFullPosition.length > 0" :path="shownPathFullPosition" :milestones="shownPathMilestones"></path-map>
              <button class="btn scale" @click="mapScaleToggle">
                <img :src="mapBtnSvg" alt="地圖縮放鍵">
              </button>
            </div>
          </div>
        </template>
      </ColumnLayout>
    </section>
    <b-modal modal-class="download-diagram" ref="diagram-modal" size="lg" centered hide-header hide-footer v-model="showDiagramModal">
      <path-download-diagram :path-serial="serial" :path-info="pathDetail" :window-width="windowWidth" @close-modal="downloadDiagramClicked()">
        <journey-drawer class="d-none" v-if="drawerDataReady" style="width: 100%" ref="rcanvas" :size="1000" :showInfo="true" :journey="pathDetail" :day-index="-1" :hide-blank-milestones="drawConfig.hideBlankMilestones" :config="drawConfig.config" :title="pathDetail.name"></journey-drawer>
      </path-download-diagram>
<!--      <div>-->
<!--        <journey-drawer v-if="drawerDataReady" style="width: 100%" ref="rcanvas" :size="1000" :showInfo="true" :journey="pathDetail" :day-index="-1" :hide-blank-milestones="drawConfig.hideBlankMilestones" :config="drawConfig.config" :title="pathDetail.name"></journey-drawer>-->
<!--      </div>-->
    </b-modal>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import moment from "moment";

import ColumnLayout from '@/components/common/ColumnLayout';
import JourneyDay from "@/components/common/JourneyDay";
import PathMap from "@/components/common/PathMap";
import JourneyDrawer from '@/components/common/JourneyDrawer';
import PathDownloadDiagram from "@/views/path/PathDownloadDiagram";

import MapEnlargeSVG from '@/assets/images/svgs/common/map-enlarge.svg';
import MapReduceSVG from '@/assets/images/svgs/common/map-reduce.svg';

export default {
  name: 'PathDetail',
  metaInfo() {
    const title = this.pathDetail.name? `${this.pathDetail.name} | Joyhike 揪山` : 'Joyhike 揪山';
    const desc = this.pathDetail.name? `${this.pathDetail.name}，總距離${this.pathDetail.totalDistance} 公尺，耗時${this.pathDetail.totalCostMinutes}分鐘。直覺的路線查詢，適合分享的行程資訊，揪山協助您的登山計畫，讓登山的事前準備更順暢！`: '直覺的路線查詢，適合分享的行程資訊，即時更新的氣象預測，揪山協助您的登山計畫，讓登山的事前準備更順暢！';
    return {
      title: `${title}`,
      meta: [
        { itemprop: 'name', content: title },
        { name: 'description', content: desc },
        { property: 'og:title', content: title },
        { property: 'og:description', content: desc },
        { property: 'og:site_name', content: title },
        { name: 'twitter:title', content: title },
        { name: 'twitter:description', content: desc },
        { name: 'twitter:card', content: 'summary' },
      ],
    }
  },
  data() {
    return {
      readInfoBusyName: 'READINFOBUSY',

      serial: Number(this.$route.params.id),
      tempId: -1,
      pathDetail: {},
      oldPathDetail: {},
      isFavorite: false,
      mapScale: false,
      windowWidth: window.innerWidth,
      trailNavHeight: 0,

      shownPathMilestones: [],
      shownPathFullPosition: [],
      shownPathFullHeights: [],

      navInfo: [
        { name:'收藏', srcName: this.svgBookmark, function: this.favoriteBtnClicked },
        { name:'分享', srcName: 'share', function: this.copyLinkClicked },
        { name:'GPX', srcName: 'download', function: this.downloadGPXClicked },
        { name:'圖檔', srcName: 'File', function: this.downloadDiagramClicked },
      ],

      showDiagramModal: false,
      drawConfig: {
        hideBlankMilestones: false,
        config: null,
      },
      drawerDataReady: false,
    };
  },
  props: {
  },
  components: {
    ColumnLayout,
    JourneyDay,
    PathMap,
    JourneyDrawer,
    PathDownloadDiagram,
  },
  computed: {
    user() {
      return this.$store.getters.userData;
    },
    svgBookmark: function() {
      return this.isFavorite ? 'bookmark_filled' : 'bookmark_border';
    },
    fixedPaddingStyle: function () {
      if (this.windowWidth < 992) {
        return {
          paddingTop: this.trailNavHeight + 'px'
        };
      }
      return {};
    },
    planMakeBtnName: function() {
      return this.mapScale && (this.windowWidth >= 992) ? '製作' : '製作登山計畫書';
    },
    totalWalkTimeHr: function () {
      if (this.pathDetail.pathInfo) {
        return parseInt(this.pathDetail.pathInfo.totalWalkTime/60);
      }
      return '';
    },
    totalWalkTimeMin: function () {
      if (this.pathDetail.pathInfo) {
        return parseInt(this.pathDetail.pathInfo.totalWalkTime%60);
      }
      return '';
    },
    totalDistance: function () {
      if (this.pathDetail.pathInfo) {
        return (this.pathDetail.pathInfo.totalDistance/1000).toFixed(1);
      }
      return '';
    },
    totalUp: function () {
      if (this.pathDetail.pathInfo) {
        return (this.pathDetail.pathInfo.totalUp).toFixed(0);
      }
      return '';
    },
    totalDown: function () {
      if (this.pathDetail.pathInfo) {
        return (this.pathDetail.pathInfo.totalDown).toFixed(0);
      }
      return '';
    },
    mapBtnSvg() {
      return this.mapScale ? MapReduceSVG : MapEnlargeSVG;
    }
	},
  watch: {
    svgBookmark: function() {
      this.navInfo[0].srcName = this.svgBookmark;
    },
  },
  mounted: async function(){
    // console.log(this.$route.params)
    // console.log(Object.keys(this.$route.params).length)
    await this.refresh();
    await this.handleResize();
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    ...mapActions(['appendComponentBusy', 'clearComponentBusy', 'appendErrorMsg']),

    handleResize() {
      this.windowWidth = window.innerWidth;
      this.trailNavHeight = this.$refs.trailNav.clientHeight;
    },
    refresh: async function() {
      await this.$store.dispatch('appendComponentBusy', this.readInfoBusyName);
      try {
        await this.refreshFavoriteState();
        this.pathDetail = await this.$store.dispatch('api/getPathJourneyFormatPromise', this.serial);
        this.oldPathDetail = await this.$store.dispatch('api/getJourneyDetailByPathPromise', this.serial);
        this.buildFullPathAndHeights(this.pathDetail.journey);
        this.buildPathMilestones(this.pathDetail.journey);
        this.navInfo[0].srcName = this.svgBookmark;

        const dayTemplatesRequest = await this.$store.dispatch('api/getPathDayTemplatePromise', this.serial);
        this.tempId = dayTemplatesRequest[0].serial;

        this.drawConfig.hideBlankMilestones = this.pathDetail.tags.indexOf('百岳') !== -1;
        if(this.user) {
          await this.refreshFavoriteState();
          const config = await this.$store.dispatch('api/getPathDrawingConfigPromise', this.serial);
          if (config !== null) {
            this.drawConfig.config = Object.assign({}, config);
          }
          this.drawerDataReady = true;
        }
      } catch (error) {
        await this.$store.dispatch('appendErrorMsg', error.toString());
      } finally {
        await this.$store.dispatch('clearComponentBusy', this.readInfoBusyName);
      }
    },
    buildFullPathAndHeights(journeyItems) {
      // const _this = this;
      this.shownPathFullPosition.splice(0, this.shownPathFullPosition.length);
      this.shownPathFullHeights.splice(0, this.shownPathFullHeights.length);

      // journeyItems.map(function(item) {
      //   switch (item.type) {
      //     case 'milestone':
      //       _this.shownPathFullPosition.push({
      //         lat: item.detail.lat,
      //         lng: item.detail.lng
      //       });
      //       break;
      //     case 'route':
      //       _this.refactorRouteData(item);
      //       break;
      //     default:
      //       console.log(`Function buildFullPathAndHeights error!`);
      //   }
      // });
      for (let i=0;i<journeyItems.length;i++) {
        const item = journeyItems[i];
        if (item.type === 'milestone') {
          this.shownPathFullPosition.push({
            lat: item.detail.lat,
            lng: item.detail.lng
          });
        } else if (item.type === 'route') {
          const detail = item.detail;

          const routepts = detail.routepts;
          const heights = detail.heights;
          if (item.direction === 0) {
            for (let j=0;j<routepts.length;j++) {
              this.shownPathFullPosition.push({
                lat: routepts[j].lat,
                lng: routepts[j].lng
              });
            }
            for (let j=0;j<heights.length;j++) {
              this.shownPathFullHeights.push(heights[j]);
            }
          } else {
            for (let j=routepts.length -1;j>=0;j--) {
              this.shownPathFullPosition.push({
                lat: routepts[j].lat,
                lng: routepts[j].lng
              });
            }
            for (let j=heights.length - 1;j>=0;j--) {
              this.shownPathFullHeights.push(heights[j]);
            }
          }
        }
      }
    },
    // refactorRouteData(item) {
    //   const _this = this;
    //   const routeDetail = item.detail;
    //   const routepts = routeDetail.routepts;
    //   const heights = routeDetail.heights;
    //
    //   switch (item.direction) {
    //     case 0:
    //       routepts.map(function(routept) {
    //         _this.shownPathFullPosition.push({
    //           lat: routept.lat,
    //           lng: routept.lng
    //         });
    //       });
    //       heights.map(function(height) {
    //         _this.shownPathFullHeights.push(height);
    //       });
    //       break;
    //     default:
    //       routepts.reverse().map(function(routept) {
    //         _this.shownPathFullPosition.push({
    //           lat: routept.lat,
    //           lng: routept.lng
    //         });
    //       });
    //       heights.reverse().map(function(height) {
    //         _this.shownPathFullHeights.push(height);
    //       });
    //   }
    // },
    buildPathMilestones(journeyItems) {
      // const _this = this;
      this.shownPathMilestones.splice(0, this.shownPathMilestones.length);

      // journeyItems.map(function(item) {
      //   if (item.type === 'milestone') {
      //     const found = _this.shownPathMilestones.filter((m) => {
      //       return m.serial === item.detail.serial;
      //     });
      //     if (found.length === 0) {
      //       _this.shownPathMilestones.push(item.detail);
      //     }
      //   }
      // });
      for (const item of journeyItems) {
        if (item.type === 'milestone') {
          const found = this.shownPathMilestones.filter((m) => {
            return m.serial === item.detail.serial;
          });
          if (found.length === 0) {
            this.shownPathMilestones.push(item.detail);
          }
        }
      }
    },
    favoriteBtnClicked: async function() {
      if (this.user === null) {
        this.$router.push({name: 'Login'});
      } else {
        await this.$store.dispatch('appendComponentBusy', this.readInfoBusyName);
        try {
          if (this.isFavorite) {
            await this.$store.dispatch('api/postPathFavoritePromise', {
              path_id: this.serial,
              favorite_boolean: false,
            });
            await this.$store.dispatch('dialogs/showMsgModal', '已從收藏中移除');
            // alert('已從收藏中移除');
            await this.refreshFavoriteState();
            // window.trackCustomEvent('Clicked', 'unsave');
          } else {
            await this.$store.dispatch('api/postPathFavoritePromise', {
              path_id: this.serial,
              favorite_boolean: true,
            });
            await this.$store.dispatch('dialogs/showMsgModal', '已加入收藏');
            // alert('已加入收藏');
            await this.refreshFavoriteState();
            // window.trackCustomEvent('Clicked', 'save');
          }
        } catch (error) {
          await this.$store.dispatch('appendErrorMsg', error.toString());
        } finally {
          await this.$store.dispatch('clearComponentBusy', this.readInfoBusyName);
        }
      }
    },
    copyLinkClicked: function() {
      // window.copyMsg(window.location.href);
      // this.$store.dispatch('appendErrorMsg', '已複製分享連結!');
      // alert('已複製分享連結!');
      // window.trackCustomEvent('Clicked', 'share');
      const url = window.location.href;

      if (navigator.clipboard) {
        navigator.clipboard.writeText(url).then(() => {
          alert('已複製分享連結!');
        }).catch(err => {
          console.error('Failed to copy URL: ', err);
          alert('複製分享連結失敗。');
        });
      } else {
        const textArea = document.createElement('textarea');
        textArea.value = url;
        document.body.appendChild(textArea);
        textArea.select();
        try {
          document.execCommand('copy');
          alert('已複製分享連結!');
        } catch (err) {
          console.error('Failed to copy URL: ', err);
          alert('複製分享連結失敗。');
        }
        document.body.removeChild(textArea);
      }
    },
    downloadGPXClicked: function() {
      let fileUrl = 'https://joyhike.com/api/downloadPathGPS?id=' + this.serial;

      let link = document.createElement('a');
      link.href = fileUrl;
      link.target = '_blank';

      link.click();
    },
    downloadDiagramClicked: function() {
      if (this.user === null) {
        this.$router.push({name: 'Login'});
      } else {
        this.showDiagramModal = !this.showDiagramModal;
      }
    },
    pathCreateClicked: async function() {
      // let link = '/path/' + this.serial + '/createJourney';
      // const passData = {
      //   pathDetail: this.pathDetail,
      //   shownPathMilestones: this.shownPathMilestones,
      //   shownPathFullPosition: this.shownPathFullPosition,
      //   shownPathFullHeights: this.shownPathFullHeights,
      // }
      // await this.$store.commit('setPassedData', passData);
      // this.$router.push(link);
      if (this.user === null) {
        this.$store.commit('setRedirectRoute', window.location.pathname);
        this.$router.push({name: 'Login'});
      } else {
        let createInfo = {
          tempId: this.tempId,
          startDate: moment().format('YYYY-MM-DD'),
        }
        const res = await this.$store.dispatch('api/postGroupCreatePromise', createInfo);
        if(res && res.group._id) {
          await this.$router.push({ name: 'GroupDetailEdit', params: { id: res.group._id } });
        }
      }
    },
    milestoneOptClicked(index) {
      console.log(index + ' has been clicked.');
      const bt = 10 * 60 * 1000;
      let i = index;
      this.pathDetail.journey[i].endTimestamp += bt;
      for (i = index + 1; i<this.shownPathDetail.journey[i].length;i++) {
        this.pathDetail.journey[i].startTimestamp += bt;
        this.pathDetail.journey[i].endTimestamp += bt;
      }
    },
    mapScaleToggle: function () {
      this.mapScale = !this.mapScale;
      // setTimeout(() => {
      //   this.$refs.map.mapSizeChanged();
      // }, 400);
      // window.trackCustomEvent('Clicked', 'focusmap');
    },
    mapScaleEnd: function () {
      this.$refs.map.mapSizeChanged();
      console.log('mapScaleEnd')
    },
    refreshFavoriteState: async function () {
      const favoritePaths = await this.$store.dispatch('api/getPathFavoritePromise');
      this.isFavorite = favoritePaths.some(item => item.serial === this.serial);
    },
  }
}
</script>

<style lang="scss">
  @import "src/assets/scss/trail";
</style>
